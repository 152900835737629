import React from "react";
import styled from "styled-components";
import {
  AboutUsNav,
  BannerTitle,
  JobSectionList,
  Layout,
  PageContainer,
  SEO,
} from "components";
import { Col, Row } from "reactstrap";
import { VideoPlayer } from "components";
import { device } from "utils";

import backgroundImage from "images/banners/bg-about-mission.jpg";

const CareersPage = ({ location }) => {
  const title = "About Us";
  const description =
    "Learn about opportunities to join our team in reshaping laboratory science";
  const video = "https://player.vimeo.com/video/249567977";
  const videoTitle = "The ECL:<br />Built for scientists by scientists";

  return (
    <Layout clearNav location={location}>
      <SEO
        image={backgroundImage}
        title={`${title} | Careers`}
        description={description}
        uri={location.pathname}
      />
      <BannerTitle image={backgroundImage}>{title}</BannerTitle>
      <PageContainer>
        <AboutUsNav section={location && location.pathname} />
        <Row>
          <ColContent xs={12} md={12} lg={12} xl={6}>
            <h2>Build a next-generation lab for next-generation scientists</h2>
            <Introduction>
              <p>
                Emerald Cloud Lab seeks the most capable, dedicated individuals
                to join us in reshaping the future of laboratory science. If you
                have the ambition and intellectual fearlessness to take on the
                challenges that come together in bringing virtualization to the
                life sciences, read on.
              </p>
              <p>
                Please apply through the appropriate link below, or send your
                resume to careers@emeraldcloudlab.com if you don’t see a
                position below, and we will contact you if we identify a
                potentially strong match.
              </p>
            </Introduction>
            <VideoWrapperMobile>
              <VideoPlayer video={video} videoTitle={videoTitle} />
            </VideoWrapperMobile>
          </ColContent>
          <ColVideoDesktop xs={12} md={12} lg={12} xl={{ size: 5, offset: 1 }}>
            <VideoWrapperDesktop>
              <VideoPlayer video={video} videoTitle={videoTitle} />
            </VideoWrapperDesktop>
          </ColVideoDesktop>
        </Row>
      </PageContainer>
      <JobSectionList />
    </Layout>
  );
};

export default CareersPage;

const ColContent = styled(Col)`
  margin-bottom: 5rem;
`;

const VideoWrapperMobile = styled(Col)`
  display: block;
  margin-bottom: 2rem;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  position: relative;

  @media ${device.lg} {
    display: none;
  }
`;

const ColVideoDesktop = styled(Col)`
  display: none;

  @media ${device.lg} {
    display: block;
  }
`;

const VideoWrapperDesktop = styled.div`
  display: none;

  @media ${device.xl} {
    display: block;
    margin-bottom: 2rem;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    position: relative;
  }
`;

const Introduction = styled.div`
  margin-bottom: 7rem;
`;
